<template>
  <th-page-wrapper class="utilities-notifications">
    <el-row class="th-container">
      <!-- Registers -->
      <el-col :span="8" class="register-column">
        <el-table
          :data="registers.available"
          :show-header="true"
          :default-sort="{ prop: 'register_number', order: 'ascending' }"
          highlight-current-row
          height="100%"
          style="width: 100%"
          :fit="true"
          @current-change="
            (row) => {
              registers.selected = row
            }
          "
        >
          <el-table-column
            prop="branch_number"
            :label="$t('common.headers.branch.title')"
            :sortable="true"
            min-width="120"
            show-overflow-tooltip
          />
          <el-table-column
            prop="register_number"
            :label="$t('common.resource.register.singular')"
            :sortable="true"
            min-width="120"
            show-overflow-tooltip
          />
          <el-table-column
            prop="name"
            :label="$t('common.headers.name.title')"
            :sortable="true"
            min-width="120"
            show-overflow-tooltip
          />
        </el-table>
      </el-col>

      <!-- Notification -->
      <el-col
        :span="16"
        class="notification-column"
        :class="{ disabled: !registers.selected }"
      >
        <th-wrapper :title="$t('views.utilities.notifications.title')">
          <el-form>
            <!-- Message -->
            <el-form-item
              :label="$t('views.utilities.notifications.message.title')"
              for="message"
            >
              <el-input
                id="message"
                v-model="text"
                type="textarea"
                :placeholder="
                  $t('views.utilities.notifications.message.placeholder')
                "
                :rows="5"
                resize="none"
                data-lpignore="true"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
            </el-form-item>

            <!-- Command to be executed on device -->
            <el-form-item
              :label="$t('views.utilities.notifications.command.title')"
            >
              <el-radio-group v-model="command">
                <el-radio-button label="update_database">
                  {{
                    $t(
                      'views.utilities.notifications.command.option.update_database'
                    )
                  }}
                </el-radio-button>
                <el-radio-button label="send_logs">
                  {{
                    $t('views.utilities.notifications.command.option.send_logs')
                  }}
                </el-radio-button>
                <el-radio-button :label="undefined">
                  {{ $t('pages.registers.edit.form.policy_options.none') }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <!-- Show busy indicator in app -->
            <div class="mb-5">
              <el-switch
                v-model="ui"
                :disabled="!command"
                :active-text="
                  $t('views.utilities.notifications.show_busy.title')
                "
              />
            </div>

            <!-- Play a sound -->
            <div class="mb-5">
              <el-switch
                v-model="sound"
                :disabled="!text"
                :active-text="
                  $t('views.utilities.notifications.play_sound.title')
                "
              />
            </div>

            <!-- Actions -->
            <div class="flex justify-end items-center">
              <!-- Show debug information -->
              <el-checkbox v-model="showDebug">
                {{ $t('views.utilities.notifications.show_debug.title') }}
              </el-checkbox>

              <!-- Send notification -->
              <el-button type="primary" class="ml-4" @click="sendNotification">
                {{
                  $t('views.utilities.notifications.send_notification.title')
                }}
              </el-button>
            </div>
          </el-form>
        </th-wrapper>

        <th-wrapper
          v-if="showDebug"
          :title="$t('views.utilities.notifications.debug.title')"
          collapsable
        >
          <!-- Debug -->
          <pre
            >{{ $t('views.utilities.notifications.title') }}: {{
              JSON.stringify(notification, null, 2)
            }}</pre
          >
          <pre class="text-gray-600"
            >{{ $t('common.resource.register.singular') }}: {{
              JSON.stringify(registers.selected, null, 2)
            }}
          </pre>
        </th-wrapper>
      </el-col>
    </el-row>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  name: 'Notifications',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.utilities.notifications')
    }
  },
  data: () => ({
    registers: {
      available: [],
      selected: undefined
    },
    text: undefined,
    sound: false,
    command: undefined,
    ui: true,
    showDebug: false
  }),
  computed: {
    notification() {
      const n = {}

      if (this.text) {
        n.aps = {
          alert: this.text,
          sound: this.sound ? 'default' : undefined
        }
      }

      if (this.command) {
        n.data = {
          command: this.command,
          ui: this.ui
        }
      }

      return n
    }
  },
  mounted() {
    this.updateRegisters()
  },
  methods: {
    async sendNotification() {
      if (!this.registers.selected) {
        return this.$notify.error(
          this.$t('views.utilities.notifications.errors.no-register-selected')
        )
      }
      try {
        const result = await th
          .registers()
          .notify(this.registers.selected.id, this.notification)
        this.$notify.success(result.data)
      } catch (e) {
        this.$notify.error(e.message)
      }
    },
    async updateRegisters() {
      const { registers } = await this.$resourceFetch('registers')

      // only show registers that we can send notifications to
      // TODO: should probably also be filtered by bundle_id, i.e. only production bundles in production mode
      this.registers.available = registers.filter(
        (register) => register.device_configuration
      )
    }
  }
}
</script>

<style scoped>
.th-container {
  height: 100%;
}

.register-column {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid var(--border-color);
}

.notification-column {
  height: 100%;
  padding: 2rem;
}
.notification-column.disabled {
  opacity: 0.8;
  pointer-events: none;
}
</style>
